<template>
  <el-main class="setting-rule-router" v-auth="'create,update,remove'">
    <div class="header">
      <div class="title">规则权限列表</div>
      <el-button
        type="primary"
        plain
        size="small"
        icon="el-icon-document-add"
        @click.stop="edit"
        v-if="auth.create"
      >添加规则</el-button>
    </div>
    <!-- default-expand-all -->
    <el-table row-key="id" :data="table.data" v-loading="table.loading">
      <el-table-column prop="name" label="路由名称"></el-table-column>
      <el-table-column prop="type" label="路由类型">
        <template slot-scope="scope">
          <el-button
            style="cursor: initial"
            v-if="scope.row.type === 'page'"
            type="primary"
            disabled
            plain
            size="mini"
          >页面</el-button>
          <el-button style="cursor: initial" v-else type="danger" plain size="mini" disabled>请求</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="client_router" label="客户端路由">
        <template slot-scope="scope">{{ scope.row.client_router || '-' }}</template>
      </el-table-column>
      <el-table-column prop="service_router" label="服务端路由">
        <template slot-scope="scope">{{ scope.row.service_router || '-' }}</template>
      </el-table-column>
      <el-table-column prop="method" label="请求类型">
        <template slot-scope="scope">{{ scope.row.method || '-' }}</template>
      </el-table-column>
      <el-table-column label="操作" width="250">
        <template slot-scope="scope">
          <el-button
            type="primary"
            plain
            size="mini"
            v-if="scope.row.type === 'page' && auth.create"
            @click.stop="edit(scope.row, 'create')"
          >添加子规则</el-button>
          <el-button
            type="primary"
            plain
            size="mini"
            v-if="scope.row.name !== 'Root' && auth.update"
            @click.stop="edit(scope.row, 'change')"
          >编辑</el-button>
          <el-button
            type="danger"
            plain
            size="mini"
            v-if="scope.row.name !== 'Root' && !scope.row.has_child && auth.remove"
            @click="remove(scope.row)"
          >删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <edit ref="edit" :rules="table.data" @refresh="getRule"></edit>
  </el-main>
</template>

<script>
import edit from './edit.vue';
import { Rule } from '@/api/permission';
export default {
  // 机构管理
  name: 'rule',
  components: { edit },
  data() {
    return {
      table: {
        data: [],
        loading: false,
        selected: {},
      },
      auth: {
        create: true,
        update: true,
        remove: true,
      },
    };
  },
  methods: {
    getRule() {
      this.table.loading = true;
      Rule.list()
        .then((res) => {
          this.table.data = res;
          console.log(res);
        })
        .finally(() => (this.table.loading = false));
    },
    edit(data, type = 'create') {
      this.$refs.edit.toggle(true);
      this.$refs.edit.setRule(data, type);
    },
    remove(data) {
      this.$confirm('确定删除此规则？', '提示', {
        type: 'warning',
        showCancelButton: true,
      })
        .then(() => {
          Rule.remove(data.id).then(() => {
            this.$message('删除成功', 'success');
            this.getRule();
          });
        })
        .catch(() => {});
    },
  },
  mounted() {},
  created() {
    this.getRule();
  },
  computed: {},
  watch: {},
};
</script>

<style scoped lang="scss">
.setting-rule-router {
  background-color: #fff;
  height: 100%;
  // padding: 15px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  .el-table {
    flex: 1;
    width: calc(100% - 30px);
    box-sizing: border-box;
    .el-table__row > .cell {
      display: flex;
    }
    .el-table_1_column_6 {
      display: flex;
    }
  }
  .el-table__row > .cell {
    display: flex;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    margin-bottom: 10px;
    .title {
      color: $h4;
      font-weight: bold;
    }
    .el-button {
      display: flex;
      // justify-content: flex-start;
    }
  }
}
</style>
