<template>
  <el-dialog
    append-to-body
    width="500px"
    :title="title"
    :visible.sync="show"
    :before-close="reset"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <el-form ref="form" label-width="100px" :model="form.data" :rules="form.rules" @submit.native.prevent="submit">
      <el-form-item v-if="form.data.pid !== 0" label="上级规则: " prop="pid">
        <el-cascader
          placeholder="请选择上级规则"
          v-model="form.data.pid"
          :options="rules"
          :showAllLevels="false"
          :props="{ checkStrictly: true, emitPath: false, expandTrigger: 'click' , value: 'id', label: 'name'}"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="路由类型: " prop="type">
        <el-radio-group v-model="form.data.type" @change="$refs.form.clearValidate()">
          <el-radio-button label="page">页面</el-radio-button>
          <el-radio-button label="api">接口</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        v-show="form.data.type === 'page'"
        :rules="{required: form.data.type === 'page', message: '路由名称不能为空！', trigger: 'change'}"
        label="路由名称: "
        prop="client_route_name"
      >
        <el-input type="text" placeholder="请输入路由名称" v-model="form.data.client_route_name" clearable></el-input>
      </el-form-item>
      <el-form-item
        v-show="form.data.type === 'page'"
        :rules="{required: form.data.type === 'page', message: '路由路径不能为空！', trigger: 'change'}"
        label="路由路径: "
        prop="client_router"
      >
        <el-input type="text" placeholder="请输入路径" v-model="form.data.client_router" clearable></el-input>
      </el-form-item>
      <el-form-item label="路由别名: " prop="name">
        <el-input clearable type="text" placeholder="请输入路由别名" v-model="form.data.name"></el-input>
      </el-form-item>
      <el-form-item v-show="form.data.type !== 'api'" label="服务端路由: ">
        <el-input
          type="textarea"
          placeholder="请输入服务端路由名称，一个路由占用一行，默认是get请求"
          v-model="form.data.service_router"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item
        v-show="form.data.type === 'api'"
        :rules="{required: form.data.type === 'api', message: '请选择请求方式！', trigger: 'change'}"
        label="请求方式: "
        prop="method"
      >
        <el-select style="width: 100%;" placeholder="请选择请求方式！" v-model="form.data.method" filterable clearable>
          <el-option v-for="item in form.method" :key="item" :value="item">{{item}}</el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        v-show="form.data.type === 'api'"
        :rules="{required: form.data.type === 'api', message: '接口标识不能为空！', trigger: 'change'}"
        label="接口标识: "
        prop="operation"
      >
        <el-input type="text" placeholder="接口标识不能为空！" v-model="form.data.operation" filterable clearable></el-input>
      </el-form-item>
      <el-form-item v-show="form.data.type === 'page'" label="路由排序: " prop="sort">
        <el-input-number v-model="form.data.sort" :min="0" :max="255" clearable />
        <el-tooltip placement="right" content="数值越大排序越靠前">
          <i class="el-icon-warning-outline tip"></i>
        </el-tooltip>
      </el-form-item>
      <el-form-item
        v-show="form.data.type === 'api'"
        :rules="{required: form.data.type === 'api', message: '服务端路由不能为空', trigger: 'change'}"
        label="服务端路由: "
        prop="service_router"
      >
        <el-input type="text" placeholder="请输入服务端路由名称" v-model="form.data.service_router" clearable></el-input>
      </el-form-item>

      <div class="clearfix">
        <el-button class="fl" @click="toggle(false)">取消</el-button>
        <el-button
          plain
          class="fr"
          native-type="submit"
          type="primary"
          :loading="form.loading"
          :disabled="form.loading"
        >确定</el-button>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
import { Rule } from '@/api/permission';
export default {
  name: 'router',
  props: {
    rules: [],
  },
  computed: {
    title() {
      return `${this.form.isChange ? '修改' : '添加'}${this.form.data.type === 'page' ? '页面' : '接口'}`;
    },
  },
  data() {
    return {
      show: false,
      form: {
        loading: false,
        isChange: false,
        method: ['GET', 'POST', 'PUT', 'DELETE'],
        data: {
          pid: 1,
          id: 0,
          name: '',
          sort: 0,
          type: 'page',
          method: '',
          operation: '',
          client_router: '',
          client_route_name: '',
          service_router: '',
        },
        rules: {
          pid: [{ required: true, message: '请选择上级路由！', trigger: 'change' }],
          name: [{ required: true, message: '路由别名不能为空！', trigger: 'change' }],
        },
      },
    };
  },
  methods: {
    setRule(data, type = 'create') {
      this.form.isChange = type === 'change';
      this.$nextTick().then(() => {
        if (this.form.isChange) {
          this.form.data = { ...data };
        } else {
          this.form.data.pid = data.id || 1;
        }
      });
    },
    toggle(show) {
      this.show = show;
      !show && this.reset();
    },
    reset(done) {
      this.$refs.form.resetFields();
      this.form.isChange = false;
      done && done();
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.form.loading = true;
          if (this.form.data.type === 'api') {
            this.form.data.method = this.form.data.method ? this.form.data.method : null;
            this.form.data.client_route_name = '';
          } else {
            this.form.data.method = null;
            this.form.data.operation = '';
          }
          Rule[this.form.isChange ? 'update' : 'create'](this.form.data)
            .then(() => {
              this.$message(this.form.isChange ? '修改成功' : '添加成功');
              this.$emit('refresh');
              this.toggle(false);
            })
            .finally(() => (this.form.loading = false));
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.tip {
  font-size: 18px;
  color: #faad14;
  margin-left: $space_mini;
}
</style>